import React, { useCallback, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import HomeIcon from '@mui/icons-material/Home'
import { useRouter } from 'next/router'

const AdminToolbar = ({ hasEditorAccess }: { hasEditorAccess: boolean }) => {
    const portalUrl = `${process.env.NEXT_PUBLIC_JOTAINFO}/wp-admin`
    const redirectUrlRef = useRef(`${portalUrl}/post.php?post=5&action=edit`)
    const router = useRouter()
    const [pageName, setPageName] = useState<string>('página')
    const [showButton, setShowButton] = useState<boolean>(false)

    const mountUrl = useCallback(
        async (pageType: string, id: number) => {
            if (pageType == 'post') {
                redirectUrlRef.current = `${portalUrl}/post.php?post=${id}&action=edit`
            } else if (pageType == 'category') {
                setPageName('categoria')
                redirectUrlRef.current = `${portalUrl}/term.php?taxonomy=category&tag_ID=${id}&post_type=post`
            } else if (pageType == 'tag') {
                setPageName('tag')
                redirectUrlRef.current = `${portalUrl}/term.php?taxonomy=post_tag&tag_ID=${id}&post_type=post`
            } else if (pageType == 'author') {
                setPageName('usuário')
                redirectUrlRef.current = `${portalUrl}/user-edit.php?user_id=${id}`
            } else if (router.pathname == '/') {
                setShowButton(true)
                redirectUrlRef.current = `${portalUrl}/post.php?post=5&action=edit`
            } else {
                setShowButton(false)
                redirectUrlRef.current = portalUrl
            }

            setShowButton(true)
        },
        [portalUrl, router.pathname]
    )

    const redirect = useCallback(async () => {
        const id = router?.query?.ID
        const pageType = router?.query?.type

        if (!id) {
            let cleanSlug = router.asPath.split('/').pop()

            if (cleanSlug == 'poder') {
                cleanSlug = 'executivo'
            }

            const url = `${process.env.NEXT_PUBLIC_API_JU_ROUTES}?url=${cleanSlug}`

            const response = await fetch(url)
            const post = await response.json()

            mountUrl(post.type, post.ID)
        }

        if (pageType && id) {
            mountUrl(String(pageType), Number(id))
        }
    }, [mountUrl, router])

    useEffect(() => {
        if (hasEditorAccess) {
            redirect()
        }
    }, [hasEditorAccess, redirect])

    return (
        <>
            {hasEditorAccess && (
                <div className='fixed-header' data-testid='admin-toolbar'>
                    <div className='nav'>
                        <Link href={portalUrl}>
                            <div className='nav-item'>
                                <HomeIcon fontSize='small' className='mr' />{' '}
                                Admin
                            </div>
                        </Link>

                        {showButton && (
                            <Link href={redirectUrlRef.current}>
                                <div className='nav-item'>
                                    <EditIcon fontSize='small' className='mr' />{' '}
                                    Editar {pageName}
                                </div>
                            </Link>
                        )}

                        <div className='nav-item'>
                            <div className='dropdown'>
                                <Link href={`${portalUrl}/post-new.php`}>
                                    <AddIcon fontSize='small' className='mr' />{' '}
                                    Novo
                                </Link>
                                <div className='dropdown-content'>
                                    <Link href={`${portalUrl}/post-new.php`}>
                                        Post
                                    </Link>
                                    <Link href={`${portalUrl}/media-new.php`}>
                                        Mídia
                                    </Link>
                                    <Link
                                        href={`${portalUrl}/post-new.php?post_type=page`}
                                    >
                                        Página
                                    </Link>
                                    <Link
                                        href={`${portalUrl}/post-new.php?post_type=faq`}
                                    >
                                        Pergunta
                                    </Link>
                                    <Link href={`${portalUrl}/user-new.php`}>
                                        Usuário
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AdminToolbar
